import React, {useState} from 'react';
import {Form, Button, Input, Message, Segment} from "semantic-ui-react";
import axios from "axios";
import './Register.css';

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const Register = (props) => {

    const {orgId, orgName} = props;

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState([]);
    const [saving, setSaving] = useState(false);

    const handleSubmit = async () => {
        setError([]);
        const errors = validateForm();
        if (errors.length > 0) {
            setError(errors);
            return;
        }

        setSaving(true);

        const firstName = name.split(' ')[0];
        const lastName = name.split(' ')[1];

        const data = {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            organization_id: orgId,
            organization_name: orgName,
        }
        console.log('Form data: ', data);

        await axios.post(`${REACT_APP_API_SERVER}/users/create`, data)
            .then((result) => {
                props.callback();
            })
            .catch((e) => {
                setError([e.response.data.message]);
            }).finally(() => {
                setSaving(false);
            })
    }

    const handleInput = (e, {name, value}) => {
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'phone':
                if (value.length <= 10) {
                    setPhone(value);
                }
                break;
            default:
                console.log('not set');
        }
    }

    const validateForm = () => {
        const errors = [];
        if (name.length === 0) {
            errors.push('First and Last name is required.');
        }

        if (phone.length !== 10) {
            errors.push('Phone is required.');
        }

        return errors;
    }

    return (
        <Segment basic className='register'>
            <Form>
                <Form.Field>
                    <label>Name</label>
                    <Input name='name' value={name} onChange={handleInput} placeholder='John Smith' autoFocus />
                </Form.Field>
                <Form.Field>
                    <label>Phone number</label>
                    <Input type='number' name='phone' value={phone} onChange={handleInput} placeholder='5125551212' />
                </Form.Field>

                {error && error.length > 0 &&
                <Message negative>
                    <p>
                        {error.map(e => (
                            <div>{e}</div>
                        ))}
                    </p>
                </Message>
                }

                <div className='submit-btn'>
                    <Button loading={saving} type='submit' primary onClick={handleSubmit}>Save</Button>
                </div>

            </Form>

        </Segment>
    )
}