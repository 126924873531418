import React from 'react';


export const NotFound = () => {
    return (
        <div style={{textAlign: 'center', color: '#ffffff'}}>
            <h1>Page not found</h1>
            <h2>¯\_(ツ)_/¯</h2>
        </div>
    )
}