import React, {useEffect, useState} from 'react';

import 'semantic-ui-css/semantic.min.css';

import {Route, Switch} from 'react-router';
import {HashRouter as Router} from 'react-router-dom';

import {Players} from "./views/Players";
import {NotFound} from "./views/NotFound";
import {AdminMenu} from "./components/AdminMenu";
import {Header} from "./components/Header";
import {Landing} from "./views/Landing";
import {Register} from "./views/Register";
import {Events} from "./views/Events";
import {EventsPast} from "./views/EventsPast";
import {Invite} from "./views/Invite";
import {Messages} from "./views/Messages";
import {Verify} from "./views/Verify";
import {Home} from "./views/Home";
import {PlayerHome} from "./views/PlayerHome";

import './App.css';
import {Container, Loader, Dimmer} from "semantic-ui-react";
import {PusherProvider} from "@harelpls/use-pusher";
import axios from "axios";

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

const App = () => {

    const [checkingAuth, setCheckingAuth] = useState(true);
    const [verified, setVerified] = useState(false);
    const [verifiedPlayer, setVerifiedPlayer] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [playerLoginToken, setPlayerLoginToken] = useState(localStorage.getItem('player_token'));

    useEffect(() => {

        if (token) {
            axios.post(`${REACT_APP_API_SERVER}/admin/verify`, {
                token
            })
              .then((res) => {
                  setVerified(res.data);
              })
              .catch((e) => {
                  console.log('Error verifying user', e);
                  setToken('');
              })
              .finally(() => {
                  setCheckingAuth(false);
              })
        }

    }, []);

    // is this a player login?
    useEffect(() => {

        if (playerLoginToken) {
            axios.post(`${REACT_APP_API_SERVER}/users/verify`, {
                playerLoginToken
            })
              .then((res) => {
                  setVerifiedPlayer(res.data);
                  // remove the admin token
                  window.localStorage.removeItem('token');
              })
              .catch((e) => {
                  console.log('Error verifying player', e);
                  setPlayerLoginToken('');
              })
              .finally(() => {
                  setCheckingAuth(false);
              })
        }
    }, []);

    const isInvitation = document.location.hash.startsWith('#/invite');
    const isVerify = document.location.hash.startsWith('#/verify');
    // const isRegister = document.location.hash.startsWith('#/register');

    // Handle non admin pages
    if (isInvitation || isVerify) {
        return (
            <PusherProvider clientKey={process.env.REACT_APP_PUSHER_KEY || 'c2adb900d4ff66d84292'}
                            cluster={process.env.REACT_APP_PUSHER_CLUSTER || 'us2'}>
                <div className='App'>
                    <Router>
                        <Container>
                            <Switch>
                                <Route path="/invite/:eventId/:token">
                                    <Invite/>
                                </Route>
                                <Route path="/verify/:token">
                                    <Verify/>
                                </Route>

                                <Route path="*">
                                    <NotFound/>
                                </Route>
                            </Switch>
                        </Container>

                    </Router>
                </div>
            </PusherProvider>
        )
    }

    // Admin Pages have a verified admin user
    if (verified) {
        return (
            <div className='App'>
                <Router>
                    <AdminMenu user={verified}/>
                    <Container>
                        <Header logo={verified.logo} orgName={verified.name}/>
                        <Switch>
                            <Route path="/" exact>
                                <Home user={verified}/>
                            </Route>
                            <Route path="/players" exact>
                                <Players user={verified}/>
                            </Route>
                            <Route path="/events" exact>
                                <Events user={verified}/>
                            </Route>
                            <Route path="/events-past" exact>
                                <EventsPast user={verified}/>
                            </Route>
                            <Route path="/messages" exact>
                                <Messages user={verified}/>
                            </Route>

                            {/* Maybe only allow admin to add user? */}
                            <Route path="/register" exact>
                                <Register user={verified}/>
                            </Route>

                            <Route path="*">
                                <NotFound/>
                            </Route>
                        </Switch>
                    </Container>

                </Router>
            </div>
        );
    }

    // Admin Pages have a verified player
    if (verifiedPlayer) {
        return (
          <div className='App'>
              <Router>
                  <Container>
                      <Header logo={verifiedPlayer.logo} orgName={verifiedPlayer.name}/>
                      <Switch>
                          <Route path="/" exact>
                              <PlayerHome player={verifiedPlayer}/>
                          </Route>
                          <Route path="*">
                              <NotFound/>
                          </Route>
                      </Switch>
                  </Container>
              </Router>
          </div>
        );
    }

    // Show the landing page
    if (!token) {
        return (
            <Container>
                <Header/>
                <Landing/>
            </Container>
        )
    }

    return (
        <Dimmer active>
            <Loader size='huge' content='Loading...'>
            </Loader>
        </Dimmer>
    )

}

export default App;