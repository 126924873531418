import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Checkbox, Modal, Segment, Table, Form, Input, TextArea} from "semantic-ui-react";
import {DateInput, TimeInput} from 'semantic-ui-calendar-react';

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const EventCreate = (props) => {

    const {user} = props;

    const [loadingPlayers, setLoadingPlayers] = useState(false);
    const [creating, setCreating] = useState(false);
    const [players, setPlayers] = useState([]);
    const [greeting, setGreeting] = useState('');
    const [location, setLocation] = useState('');
    const [message, setMessage] = useState('');
    const [startAt, setStartAt] = useState('');
    const [startTime, setStartTime] = useState('');
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);

    useEffect(() => {
        setLoadingPlayers(true);
        axios.get(`${REACT_APP_API_SERVER}/users/all`, {
            params: {
                orgId: user.id
            }
        }).then((res) => {
            setPlayers(res.data);
            setLoadingPlayers(false);
        }).catch((e) => {
            alert('Error loading players');
            setLoadingPlayers(false);
        });
    }, []);

    const handlePlayerCheckboxChange = (e, {checked, playerid}) => {
        const player = players.find(p => p.id === playerid);
        player.selected = checked;
    }

    const checkAllPLayers = (e, {checked}) => {
        players.forEach((player) => {
            player.selected = checked;
            document.getElementById(`cb-${player.id}`).checked = checked;
        })
    }

    const handleDateChange = (e, {value, date}) => {
        setStartAt(value);
    }

    const handleStartTimeChange = (e, {value}) => {
        setStartTime(value);
    }

    const handleCreateClick = () => {
        setCreating(true);

        const invitees = players.filter(p => p.selected === true);

        if (invitees.length === 0) {
            setCreating(false);
            alert('Select at least one player');
            return;
        }

        if (min !== 0 && min >= max) {
            setCreating(false);
            alert('The maximum number of players can not be less than the minimum.');
            return;
        }

        axios.post(`${REACT_APP_API_SERVER}/event/create`, {
            startAt,
            startTime,
            message,
            greeting,
            location,
            min,
            max,
            players: invitees,
            token: localStorage.getItem('token')
        }).then((res) => {
            props.handleClose();
        }).catch((e) => {
            alert('Error trying to create event');
        }).finally(() => {
            setCreating(false);
        })

    }

    return (
        <Modal
            size='large'
            dimmer='blurring'
            open={true}
        >
            <Modal.Header>Create Event</Modal.Header>
            <Modal.Content>
                <Segment loading={loadingPlayers} basic>

                    <h2>Event Details</h2>
                    <Segment basic>
                        <Form>
                            <Form.Field>
                                <label>Text/SMS Greeting</label>
                                <Input value={greeting} onChange={(e, {value}) => setGreeting(value)}
                                       placeholder='This is the greeting in the text message invite'/>
                            </Form.Field>
                            <Form.Field>
                                <label>Details</label>
                                <TextArea value={message} onChange={(e, {value}) => setMessage(value)}
                                       placeholder='say something about the event'/>
                            </Form.Field>
                            <Form.Field>
                                <label>Location</label>
                                <Input value={location} onChange={(e, {value}) => setLocation(value)}
                                       placeholder='where this going to take place...'/>
                            </Form.Field>
                            <Form.Field>
                                <label>Date</label>
                                <DateInput value={startAt} onChange={handleDateChange} dateFormat='YYYY-MM-DD'
                                           minDate={new Date()} closable={true} clearable={true}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Time</label>
                                <TimeInput value={startTime} onChange={handleStartTimeChange} timeFormat='AMPM'
                                           closable={true} clearable={true}/>
                            </Form.Field>
                            <Segment>
                                <p>
                                    Set a minimum and maximum number of participants.  Leave it set to zero if there is no min or max.
                                </p>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Minimum Participants</label>
                                        <Input type='number' value={min} onChange={(e, {value}) => setMin(Number(value))}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Maximum Participants</label>
                                        <Input type='number' value={max} onChange={(e, {value}) => setMax(Number(value))}/>
                                    </Form.Field>
                                </Form.Group>
                            </Segment>

                        </Form>

                        <h2>Select Players</h2>
                        <Table stackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Phone</Table.HeaderCell>
                                    <Table.HeaderCell><Checkbox toggle onChange={checkAllPLayers}/></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    players.map(player => (
                                        <Table.Row>
                                            <Table.Cell>{player.first_name} {player.last_name}</Table.Cell>
                                            <Table.Cell>{player.phone}</Table.Cell>
                                            <Table.Cell>
                                                <Checkbox toggle id={`cb-${player.id}`} playerid={player.id}
                                                          onChange={handlePlayerCheckboxChange}/>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>


                        <Button style={{marginTop: 20}} primary onClick={handleCreateClick}
                                loading={creating}>Create</Button>
                    </Segment>

                </Segment>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}