import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Icon, Modal, Segment, Table, Confirm} from "semantic-ui-react";
import Moment from 'react-moment'
import moment from "moment";
import './EventDetails.css';

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const EventDetails = (props) => {

    const {start_at, start_time, message, type} = props.event;

    const [loading, setLoading] = useState(false);
    const [players, setPlayers] = useState([]);
    const [playersNotInvited, setPlayersNotInvited] = useState([]);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const loadEventData = () => {
        setLoading(true);
        axios.get(`${REACT_APP_API_SERVER}/event/${props.event.id}/players`).then((res) => {
            setPlayers(res.data);
            // Now get the not invited players
            const playersInvitedAlready = [];
            res.data.forEach((p) => {
                playersInvitedAlready.push(p.player_id);
            });

            axios.post(`${REACT_APP_API_SERVER}/event/${props.event.id}/players_not_invited`, {
                playersInvitedAlready
            }).then((res) => {
                setPlayersNotInvited(res.data);
            }).finally(() => {
                setLoading(false);
            });
        });
    }

    const cancelEventForRealLife = () => {
        axios.delete(`${REACT_APP_API_SERVER}/event/${props.event.id}`).then((res) => {
            // Close the modal
            props.refreshData();
            props.handleClose();
        }).catch((e) => {
            console.log('Error deleting the event', e);
        });
    }

    useEffect(() => {
        loadEventData();
    }, [props.event.id]);

    const invitePlayer = (e, {player}) => {
        axios.post(`${REACT_APP_API_SERVER}/event/${props.event.id}/invite_player`, {
            playerId: player.id,
            firstName: player.first_name,
            phone: player.phone
        }).then((res) => {
            loadEventData();
        }).catch((e) => {
            console.log('Error deleting the event', e);
            setOpenDeleteConfirm(false);
        })
    }

    const removeInvitation = (e, {invitationId}) => {
        setLoading(true);
        axios.delete(`${REACT_APP_API_SERVER}/invite/${invitationId}`).then((res) => {
            loadEventData();
        }).catch((e) => {
            console.log('Error in removeInvitation', e);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            size='large'
            dimmer='blurring'
            open={props.open}
        >
            <Modal.Header>
                <Moment format='dddd MMMM Do' date={moment.utc(`${start_at}`)}/> at {start_time}

                <div className='top-buttons'>
                    <Button basic icon onClick={loadEventData}>
                        <Icon color='blue' name='refresh'/>
                    </Button>
                    <Button basic icon onClick={props.handleClose}>
                        <Icon color='blue' name='close'/>
                    </Button>
                </div>


            </Modal.Header>
            <Modal.Content>
                <Segment basic loading={loading}>
                    <div>
                        Type of play: {type} <br/>
                        When: <Moment format='dddd MMMM Do' date={moment.utc(`${start_at}`)}/> at {start_time} <br/>
                        Details: {message}
                    </div>

                    <Segment>
                        <Button basic onClick={() => setOpenDeleteConfirm(true)}>Cancel Event?</Button>
                        <Confirm
                            open={openDeleteConfirm}
                            header='Cancel Event'
                            content='Are you sure you want to cancel this event?'
                            onCancel={() => setOpenDeleteConfirm(false)}
                            onConfirm={cancelEventForRealLife}
                        />
                    </Segment>

                    <h2>Players Invited</h2>
                    <Table stackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Player</Table.HeaderCell>
                                <Table.HeaderCell>Attending</Table.HeaderCell>
                                <Table.HeaderCell>Last Viewed</Table.HeaderCell>
                                <Table.HeaderCell>Invitation</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {players &&
                                players.map(player => (
                                <Table.Row>
                                    <Table.Cell>{player.first_name} {player.last_name}</Table.Cell>
                                    <Table.Cell>{player.attending}</Table.Cell>
                                    <Table.Cell>
                                        {player.last_viewed_at &&
                                            moment(player.last_viewed_at).fromNow()
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a href={player.invitation_url} rel='noreferrer' target='_blank'>Invitation ({player.invitation_id})</a>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button size='tiny' negative invitationId={player.invitation_id} onClick={removeInvitation}>Remove</Button>
                                    </Table.Cell>
                                </Table.Row>
                                ))
                            }

                        </Table.Body>
                    </Table>

                    {!props.past && playersNotInvited && playersNotInvited.length > 0 &&
                        <div>
                            <h2>Players Not Invited</h2>
                            <Table stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Player</Table.HeaderCell>
                                        <Table.HeaderCell>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {playersNotInvited &&
                                    playersNotInvited.map(player => (
                                        <Table.Row>
                                            <Table.Cell>{player.first_name} {player.last_name}</Table.Cell>
                                            <Table.Cell>
                                                <Button size='tiny' primary player={player}
                                                        onClick={invitePlayer}>Invite</Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                    }

                                </Table.Body>
                            </Table>
                        </div>
                    }

                </Segment>
            </Modal.Content>
        </Modal>
    )
}