import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {Message, Segment} from "semantic-ui-react";
import './Verify.css';

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const Verify = () => {

    const {token} = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [player, setPlayer] = useState();

    useEffect(() => {
        setLoading(true);
        axios.get(`${REACT_APP_API_SERVER}/users/verify_phone/${token}`)
            .then((res) => {
                setPlayer(res.data);
            })
            .catch((e) => {
                if (e.response.data === 'verified') {
                    setError('verified');
                } else {
                    setError('unverified')
                }

            })
            .finally(() => {
                setLoading(false);
            });

    }, [token]);

    if (error && error === 'verified') {
        return (
            <Segment className='verify'>
                <Message positive>You're phone number has been verified.</Message>
            </Segment>
        )
    }

    if (error && error === 'unverified') {
        return (
            <Segment className='verify'>
                <Message negative>Error! Could not verify your phone number.</Message>
            </Segment>
        )
    }

    return (
        <Segment loading={loading} className='verify'>

            {loading &&
                <Message>
                    Verifying your information.
                </Message>
            }

            {player &&
                <Message positive>
                    <p>
                        Thanks {player.first_name}!  You've been registered at {player.org_name}.
                    </p>
                </Message>
            }
        </Segment>
    )
}