import React from 'react';
import {Button, Segment} from "semantic-ui-react";
import './Home.css';
import {useHistory} from "react-router-dom";

export const Home = (props) => {

    const {user} = props;

    const history = useHistory();

    const handleItemClick = (e, {name}) => {
        history.push(`/${name}`)
    }

    return (
        <div className='home'>

            <Segment>
                <h2>Welcome {user.first_name}</h2>

                <Button.Group widths={2} basic>
                    <Button name='players' onClick={handleItemClick}>Players</Button>
                    <Button name='events' onClick={handleItemClick}>Events</Button>
                </Button.Group>

            </Segment>

        </div>
    )
}