import React from 'react';
import {Segment} from "semantic-ui-react";
import './PlayerHome.css';
import {useHistory} from "react-router-dom";

export const PlayerHome = (props) => {

    const {player} = props;

    const history = useHistory();

    const handleItemClick = (e, {name}) => {
        history.push(`/${name}`)
    }

    return (
        <div className='player-home'>

            <Segment>
                <h2>Welcome {player.first_name}</h2>
                <p>
                    You are logged in.
                </p>
            </Segment>

        </div>
    )
}