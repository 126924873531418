import React, {useState} from 'react';
import {Button, Segment, Modal} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {AdminLogin} from './AdminLogin';
import './Landing.css';


export const Landing = () => {

    const [loginType, setLoginType] = useState(null);
    const [open, setOpen] = useState(false);

    const handleAdminLoginClick = (e) => {
        setLoginType('admin');
        setOpen(true);
    }

    return (
        <div className='landing'>

            <Segment basic>

                <Button basic color='blue' size='huge' onClick={handleAdminLoginClick}>Admin Login</Button>

            </Segment>

            <Modal
                open={open}
                closeIcon={true}
                closeOnDimmerClick={false}
                onClose={() => setOpen(false)}>

                {loginType === 'admin' &&
                    <AdminLogin/>
                }

                {loginType === 'user' &&
                    <Segment basic>
                        <h1>To be implemented</h1>
                    </Segment>
                }
            </Modal>

        </div>
    )
}