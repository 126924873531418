import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Segment, Table} from "semantic-ui-react";
import {EventDetails} from './EventDetails';
import {EventCreate} from './EventCreate';
import Moment from 'react-moment'

import './Events.css';
import moment from "moment";

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const EventsPast = () => {


    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [event, setEvent] = useState();
    const [events, setEvents] = useState([]);

    const loadEvents = () => {
        axios.get(`${REACT_APP_API_SERVER}/event/past`)
            .then((res) => {
                setEvents(res.data);
            })
            .catch((e) => {
                console.log('Error getting event', e);
                alert('Error getting event');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        loadEvents();
    }, []);

    const handleEventClick = (e, {event}) => {
        setEvent(event);
        setShowModal(true);
    }

    return (
        <Segment loading={loading}>
            <h2>Past Events</h2>
            <Table stackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Day</Table.HeaderCell>
                        <Table.HeaderCell>Start Time</Table.HeaderCell>
                        <Table.HeaderCell>Format</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Message</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        events.map(event => (
                            <Table.Row>
                                <Table.Cell>
                                    <Button event={event} onClick={handleEventClick}>
                                        <Moment format='dddd MMMM Do' date={moment.utc(`${event.start_at}`)}/>
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{event.start_time}</Table.Cell>
                                <Table.Cell>{event.type}</Table.Cell>
                                <Table.Cell>{event.status}</Table.Cell>
                                <Table.Cell>{event.message}</Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>
            </Table>


            {showModal && event &&
                <EventDetails event={event} open={showModal} handleClose={() => setShowModal(false)} refreshData={loadEvents}/>
            }

        </Segment>
    )
}