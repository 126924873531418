import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Segment, Table} from "semantic-ui-react";
import {EventDetails} from './EventDetails';
import {EventCreate} from './EventCreate';
import Moment from 'react-moment'

import './Events.css';
import moment from "moment";

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const Events = (props) => {

    const {user} = props;

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [event, setEvent] = useState();
    const [events, setEvents] = useState([]);


    const loadEvents = () => {
        axios.post(`${REACT_APP_API_SERVER}/event/upcoming`, {
            user: user
        })
            .then((res) => {
                setEvents(res.data);
            })
            .catch((e) => {
                console.log('Error loading events', e);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        loadEvents();
    }, []);

    const handleEventClick = (e, {event}) => {
        setEvent(event);
        setShowModal(true);
    }

    const handleCreateEvent = () => {
        setShowCreateModal(true);
    }

    const handleClose = () => {
        loadEvents()
        setShowCreateModal(false);
    }

    return (
        <Segment loading={loading}>
            <h2>Upcoming Events</h2>
            <div className='events-actions'>
                <Button primary onClick={handleCreateEvent}>Create Event</Button>
            </div>

            {events && events.length > 0 ?
                <Table stackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Day</Table.HeaderCell>
                            <Table.HeaderCell>Start Time</Table.HeaderCell>
                            <Table.HeaderCell>Format</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Message</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            events.map(event => (
                                <Table.Row>
                                    <Table.Cell>
                                        <Button event={event} onClick={handleEventClick}>
                                            <Moment format='dddd MMMM Do' date={moment.utc(`${event.start_at}`)}/>
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>{event.start_time}</Table.Cell>
                                    <Table.Cell>{event.type}</Table.Cell>
                                    <Table.Cell>{event.status}</Table.Cell>
                                    <Table.Cell>{event.message}</Table.Cell>
                                </Table.Row>
                            ))
                        }

                    </Table.Body>
                </Table>

                :

                <Segment>
                    <h3 style={{textAlign: 'center'}}>No upcoming events.</h3>
                </Segment>
            }


            {showModal && event &&
                <EventDetails user={user} event={event} open={showModal} handleClose={() => setShowModal(false)} refreshData={loadEvents} past={false}/>
            }

            {showCreateModal  &&
                <EventCreate user={user} handleClose={handleClose}/>
            }

        </Segment>
    )
}