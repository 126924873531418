import React, {useState, useEffect} from 'react';
import {Menu, Dropdown} from "semantic-ui-react";
import {useHistory} from "react-router-dom";

import './AdminMenu.css'


export const AdminMenu = (props) => {

    const history = useHistory();
    const pathname = history.location.pathname;
    const {user} = props;

    const [activeItem, setActiveItem] = useState(pathname.substr(1));

    const handleItemClick = (e, {name}) => {

        if (name === 'home') {
            setActiveItem(name);
            history.push(`/`)
        } else if (name) {
            setActiveItem(name);
            history.push(`/${name}`)
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }

    if (!user) {
        return null;
    }

    return (
        <Menu className='main-menu' inverted>
            <Dropdown item icon='bars'>
                <Dropdown.Menu>
                    <Dropdown.Item name='home' active={activeItem === 'home'} onClick={handleItemClick}>Home</Dropdown.Item>
                    <Dropdown.Item name='players' active={activeItem === 'players'} onClick={handleItemClick}>Players</Dropdown.Item>
                    <Dropdown.Item name='events' active={activeItem === 'events'} onClick={handleItemClick}>Upcoming Events</Dropdown.Item>
                    {/*<Dropdown.Item name='messages' active={activeItem === 'messages'} onClick={handleItemClick}>Messages</Dropdown.Item>*/}
                    {/*<Dropdown.Item name='events-past' active={activeItem === 'events-past'} onClick={handleItemClick}>Past Events</Dropdown.Item>*/}
                    <Dropdown.Divider />
                    <Dropdown.Item name='logout' className='logout-item' onClick={handleLogout}><span className='logout'>Logout</span></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Menu>
    )
}