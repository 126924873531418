import React from 'react';

import './Header.css'
import {Image} from "semantic-ui-react";

export const Header = ({logo, orgName}) => {

    if (logo) {
        return (
            <div className='logo'>
                <Image src={logo} centered className='logo-img'/>
            </div>
        )
    } else {
        return (
            <div className='logo'>
                <h1 className='header-title'>{orgName}</h1>
            </div>
            )

    }
}