import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Segment, Table} from "semantic-ui-react";
import Moment from 'react-moment'
import {useChannel, useEvent} from "@harelpls/use-pusher";

import './Events.css';
import moment from "moment";

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const Messages = () => {

    const channel = useChannel('messages');

    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);


    useEvent(channel, "update", ((data) => {
        loadMessages();
    }));

    const loadMessages = () => {
        setLoading(true);
        axios.get(`${REACT_APP_API_SERVER}/message/all`)
            .then((res) => {
                setMessages(res.data);
            })
            .catch((e) => {
                console.log('Error loading events', e);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        loadMessages();
    }, []);

    if (messages.length === 0) {
        return (
            <Segment>
                <h1 style={{textAlign: "center"}}>
                    No messages!
                </h1>
            </Segment>
        )
    }

    return (
        <Segment loading={loading}>
            <h2>Messages</h2>
            <Table compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>From</Table.HeaderCell>
                        <Table.HeaderCell>Phone</Table.HeaderCell>
                        <Table.HeaderCell>Event</Table.HeaderCell>
                        <Table.HeaderCell>Message</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        messages.map(message => (
                            <Table.Row>
                                <Table.Cell><Moment format='MMMM Do, YYYY hh:mm a' date={moment.utc(`${message.created_on}`).utcOffset(-8)}/></Table.Cell>
                                <Table.Cell>{message.first_name} {message.last_name} ({message.skill_level})</Table.Cell>
                                <Table.Cell>{message.phone}</Table.Cell>
                                <Table.Cell>{message.event_id}</Table.Cell>
                                <Table.Cell>{message.message}</Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>
            </Table>


            {/*{showModal && event &&*/}
            {/*    <EventDetails event={event} handleClose={() => setShowModal(false)}/>*/}
            {/*}*/}


        </Segment>
    )
}