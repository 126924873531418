import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Header, Table, Button, Segment, Modal, Form, Input, Message, Icon, List} from "semantic-ui-react";
import {Register} from './Register';
import './Players.css';

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const Players = (props) => {

    const {user} = props;
    const orgId = user.id;
    const orgName = user.name;

    const [loading, setLoading] = useState(false);
    const [players, setPlayers] = useState([]);
    const [open, setOpen] = useState(false);
    const [deletePlayer, setDeletePlayer] = useState(false);
    const [editPlayer, setEditPlayer] = useState(false);
    const [saving, setSaving] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState([]);

    const openDeletePlayer = (player) => {
        setDeletePlayer(player);
    }

    const cancelDeletePlayer = () => {
        setDeletePlayer(false);
    }

    const confirmDeletePlayer = async () => {
        setLoading(true);
        await axios.delete(`${REACT_APP_API_SERVER}/users/${deletePlayer.id}`)
            .catch((e) => {
                console.error(e);
                // TODO: maybe do a better error message?
                alert('Error: could not delete user.');
            }).finally(() => {
                setDeletePlayer(false);
                setLoading(false);
                loadUsers();
            })
    }

    const openEditPlayer = (player) => {
        setFirstName(player.first_name);
        setLastName(player.last_name);
        setPhone(player.phone);
        setEditPlayer(player);
    }

    const cancelEditPlayer = () => {
        setEditPlayer(false);
    }

    const validateForm = () => {
        const errors = [];
        if (firstName.length === 0) {
            errors.push('First name is required.');
        }

        if (lastName.length === 0) {
            errors.push('Last name is required.');
        }

        if (phone.length !== 10) {
            errors.push('Phone is required.');
        }

        return errors;
    }

    const savePlayerEdits = async () => {
        setSaving(true);
        setError([]);
        const errors = validateForm();
        if (errors.length > 0) {
            setError(errors);
            setSaving(false);
            return;
        }

        await axios.post(`${REACT_APP_API_SERVER}/users/update`, {id: editPlayer.id, firstName, lastName, phone})
            .catch((e) => {
                console.error(e);
                // TODO: maybe do a better error message?
                alert('Error: could not update user.');
            }).finally(() => {
                setSaving(false);
                setEditPlayer(false);
                loadUsers();
            })
    }

    const loadUsers = () => {
        setLoading(true);
        async function getUsers() {
            const res = await axios.get(`${REACT_APP_API_SERVER}/users/all`, {
                params: {
                    orgId: user.id
                }
            });
            setPlayers(res.data);
        }
        getUsers().then((data) => {
            console.log('Got users', data);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadUsers();
    }, []);

    const callBackFunction = () => {
        loadUsers();
        setOpen(false);
    }

    const handleInput = (e, {name, value}) => {
        switch (name) {
            case 'firstname':
                setFirstName(value);
                break;
            case 'lastname':
                setLastName(value);
                break;
            case 'phone':
                if (value.length <= 10) {
                    setPhone(value);
                }
                break;
            default:
                console.log('not set');
        }
    }

    return (
        <Segment className='players' loading={loading}>
            <Header as='h2'>Players</Header>

            {/* Delete player */}
            <Modal
                open={deletePlayer}>
                <Modal.Content>
                    Are you sure you want to delete {deletePlayer.first_name} {deletePlayer.last_name}?
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={cancelDeletePlayer}>Cancel</Button>
                    <Button negative onClick={confirmDeletePlayer}>Delete</Button>
                </Modal.Actions>
            </Modal>

            {/* edit player */}
            <Modal
                open={editPlayer}>
                <Modal.Header>
                    Edit Player
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>First name</label>
                            <Input name='firstname' value={firstName} onChange={handleInput} placeholder='First name' autoFocus />
                        </Form.Field>
                        <Form.Field>
                            <label>Last name</label>
                            <Input name='lastname' value={lastName} onChange={handleInput} placeholder='Last name' />
                        </Form.Field>
                        <Form.Field>
                            <label>Phone number</label>
                            <Input type='number' name='phone' value={phone} onChange={handleInput} placeholder='5125551212' />
                        </Form.Field>

                        {error && error.length > 0 &&
                        <Message negative>
                            <p>
                                {error.map(e => (
                                    <div>{e}</div>
                                ))}
                            </p>
                        </Message>
                        }

                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={cancelEditPlayer}>Cancel</Button>
                    <Button loading={saving} primary onClick={savePlayerEdits}>Save</Button>
                </Modal.Actions>
            </Modal>

            {players && players.length > 0 ?
                <div>
                    <Modal
                        closeIcon
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        trigger={<Button primary className='addBtn'>Add</Button>}
                    >
                        <Modal.Header>
                            Add Player
                        </Modal.Header>
                        <Modal.Content>
                            <Register orgId={orgId} orgName={orgName} callback={callBackFunction}/>
                        </Modal.Content>
                    </Modal>
                    <List divided verticalAlign='middle'>
                        {
                            players.map(player => (
                                <List.Item>
                                    <List.Content floated='right'>
                                        <Button basic icon="edit" onClick={() => openEditPlayer(player)}/> &nbsp;&nbsp;
                                        <Button basic icon="user delete" onClick={() => openDeletePlayer(player)}/>
                                    </List.Content>

                                    <List.Content>
                                        <div>
                                            {player.first_name} {player.last_name}
                                        </div>
                                        <div>
                                            {player.phone}

                                            {player.phone_verified_on &&
                                                <span><Icon name='check circle outline' color='green'/></span>
                                            }

                                        </div>
                                    </List.Content>
                                </List.Item>
                            ))
                        }
                    </List>
                </div>
                :
                <div className='no-players'>
                    <h1>
                        Start adding players.
                    </h1>
                    <div>
                        <Modal
                            closeIcon
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            open={open}
                            trigger={<Button primary size='huge'>Add</Button>}
                        >
                            <Register orgId={orgId} callback={callBackFunction}/>
                        </Modal>
                    </div>
                </div>
            }



        </Segment>
    )
}