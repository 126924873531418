import React, {useState} from 'react';
import {Form, Input, Button, Segment, Message} from "semantic-ui-react";
import './AdminLogin.css';
import axios from "axios";
import {useHistory} from "react-router-dom";

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';

export const AdminLogin = () => {

    const history = useHistory();

    const [username, setUsername] = useState('');
    const [user, setUser] = useState();
    const [code, setCode] = useState('');
    const [token, setToken] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState('');

    const handleUsernameChange = (e, {value}) => {
        setUsername(value);
    }

    const handleCodeChange = (e) => {
        setCode(e.currentTarget.value);
    }

    const handleLoginClick = () => {
        setLoginError('');
        setLoading(true);
        setLoginError('');

        if (username.length < 1) {
            setLoginError('Please enter your username.');
            setLoading(false);
            return;
        }

        axios.post(`${REACT_APP_API_SERVER}/admin/login`, {
            username: username
        }).then((res) => {
            if (res.data && res.data.user) {
                setUser(res.data.user);
                setCodeSent(true);
            } else {
                setLoginError('User not found.');
            }
        }).catch((e) => {
            localStorage.removeItem('token');
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleVerifyCodeClick = () => {

        setLoading(true);
        setLoginError('');

        axios.post(`${REACT_APP_API_SERVER}/admin/verify_code`, {
            username: username,
            code: code
        }).then((res) => {
            localStorage.setItem('token', res.data.token);
            window.location.href = '/';
        }).catch((e) => {
            localStorage.removeItem('token');
            setLoginError('Invalid Code');
            setLoading(false);
        });
    }

    const handleCancel = () => {
        setCode('');
        setUsername('');
        setCodeSent(false);
    }

    if (codeSent) {
        return (
            <Segment basic>
                <h1>Admin Login : Verify Code</h1>

                {user.contact_preference === 'email' ?
                    <div>
                        We sent an email with a verification code to you at <b>{user.email}</b>.
                    </div>
                    :
                    <div>
                    We sent a text message with a verification code to your phone number (XXX) XXX-<b>{user.phone.substr(6, user.phone.length)}</b>.
                    </div>
                }

                <div>
                    The code will be valid for 15 minutes. If you do not receive a text message, please contact support.
                </div>

                <Form className='login-form' loading={loading}>
                    <Form.Field>
                        <label>Code</label>
                        <Input name='code' type='text' value={code} onChange={handleCodeChange} placeholder='code sent to phone'/>
                    </Form.Field>
                    <Form.Field>
                        <Button primary onClick={handleVerifyCodeClick}>Verify Code</Button>
                        <Button secondary onClick={handleCancel}>Cancel</Button>
                        {loginError &&
                        <Message negative>{loginError}</Message>
                        }
                    </Form.Field>
                </Form>
            </Segment>
        )
    }

    return (
        <Segment basic>
            <h1>Admin Login</h1>
            <Form className='login-form' loading={loading}>
                {loginError &&
                <Message negative>{loginError}</Message>
                }
                <Form.Field>
                    <label>Username</label>
                    <Input name='username' type='text' value={username} onChange={handleUsernameChange} />
                </Form.Field>
                <Form.Field>
                    <Button primary onClick={handleLoginClick}>Log in</Button>
                </Form.Field>
            </Form>
        </Segment>
    )
}