import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Segment, Message, Icon, Popup, Comment, Dimmer} from "semantic-ui-react";
import Moment from "react-moment";
import moment from 'moment';
import {NotFound} from './NotFound';
import './Invite.css';
import {useChannel, useEvent} from "@harelpls/use-pusher";
import {useParams} from "react-router-dom";
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import {Header} from "../components/Header";

const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER || '/api';
const REACT_APP_BEAMS_URL = process.env.REACT_APP_BEAMS_URL || '/api/beams/auth';
const REACT_APP_PUSHER_BEAMS_INSTANCE = process.env.REACT_APP_PUSHER_BEAMS_INSTANCE || '';


function subscribeToBeams(jwt, userId) {
    try {
        const beamsClient = new PusherPushNotifications.Client({
            instanceId: REACT_APP_PUSHER_BEAMS_INSTANCE,
        });

        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
            url: REACT_APP_BEAMS_URL,
            queryParams: {'token': jwt}
        });

        beamsClient
            .start()
            .then(() => beamsClient.setUserId(userId, beamsTokenProvider))
            .catch((e) => {
                console.log('Error subscribeToBeams: ', e.message)
            });


    } catch (e) {
        console.log('Browser does not support Pusher Beams');
    }
}

export const Invite = () => {

    const {token, eventId} = useParams();
    const channel = useChannel(`event-${eventId}`);

    const [loading, setLoading] = useState(false);
    const [loadingPlayers, setLoadingPlayers] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [event, setEvent] = useState();
    const [players, setPlayers] = useState([]);
    const [sending, setSending] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [responded, setResponded] = useState('');
    const [inOrOut, setInOrOut] = useState('');
    const [error, setError] = useState();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [logo, setLogo] = useState('');
    const [orgName, setOrgName] = useState('');
    const [locked, setLocked] = useState(false);

    useEvent(channel, "update", ((data) => {
        setLoadingPlayers(true);
        axios.get(`${REACT_APP_API_SERVER}/event/${eventId}/players`)
            .then((res) => {
                setPlayers(res.data);
                updateEventQuota(res.data);
            })
            .catch((e) => {
                //alert('error');
                setError(e.message);
            })
            .finally(() => {
                setLoadingPlayers(false);
            });
    }));

    useEvent(channel, "update-messages", ((data) => {
        loadMessages();
    }));

    useEffect(() => {
        setLoading(true);
        axios.get(`${REACT_APP_API_SERVER}/invite/${token}`)
            .then((res) => {
                setEvent(res.data.eventData);
                setPlayers(res.data.players);
                setInOrOut(res.data.eventData.attending);
                setLogo(res.data.logo);
                setOrgName(res.data.org_name);
                subscribeToBeams(token, `user-${res.data.eventData.playerId}`);
                updateEventQuota(res.data.players);
                logPlayerIn(res.data.loginToken);
            })
            .catch((e) => {
                //alert('error');
                setError(e.message);
            })
            .finally(() => {
                setLoading(false);
            });

        loadMessages();

    }, [token]);

    const logPlayerIn = (loginToken) => {
        window.localStorage.setItem('player_token', loginToken);
    }

    const updateEventQuota = (playerData) => {
        const playerCountCommitted = playerData.filter((p) => p.attending === 'y').length;
        if (event && event.max) {
            setLocked(playerCountCommitted >= event.max);
        }
    }

    const willAttend = (e, {answer}) => {

        if (answer === inOrOut) {
            return;
        }

        if (answer === 'y' && locked) {
            alert('Sorry, the max number has been reached. If someone drops out, you can try again.');
            return;
        }

        setSending(true);
        axios.post(`${REACT_APP_API_SERVER}/invite/response`, {
            answer,
            token
        })
            .then((res) => {
                setResponded(answer);
                setInOrOut(answer);
                setTimeout(() => {
                    setResponded('');
                }, 3000);
            })
            .catch((e) => {
                //alert('error');
            })
            .finally(() => {
                setSending(false);
            })
    }


    const handleMessage = (e) => {
        setMessage(e.currentTarget.value);
    }

    const sendMessage = () => {

        if (!message || message.trim().length < 1) {
            return;
        }

        setSendingMessage(true);
        axios.post(`${REACT_APP_API_SERVER}/message/create`, {
            phone: event.phone,
            message: message,
            eventId: event.id,
            playerName: `${event.first_name} ${event.last_name}`,
            playerId: event.playerId,
            invitationId: event.invitation_id
        })
            .then((res) => {
                console.log('update data');
                // loadMessages();
            })
            .catch((e) => {
                console.log('error with message');
            })
            .finally(() => {
                setMessage('');
                setSendingMessage(false);
            })
    }

    const loadMessages = () => {
        setLoadingMessages(true);
        axios.get(`${REACT_APP_API_SERVER}/message/event/${eventId}`)
            .then((res) => {
                setMessages(res.data);
            })
            .catch((e) => {
                console.log('Error loading events', e);
            })
            .finally(() => {
                setLoadingMessages(false);
            });
    }

    if (!loading && error) {
        return (
            <NotFound/>
        )
    }

    const handleDimmerClose = () => {
        setResponded('');
    }

    return (
        <div>
            <Header logo={logo} orgName={orgName}/>
            <Segment loading={loading} className='invite'>

                {responded &&
                <Dimmer active={true} onClickOutside={handleDimmerClose} page>
                    <Message info>
                        {responded === 'y' ?
                            <div>
                                <Message.Header>See you there!</Message.Header>
                                <p>
                                    If you can't make it, please update your answer. Thanks!
                                </p>
                            </div>
                            :
                            <div>
                                <Message.Header>Sorry you can't make it!</Message.Header>
                                <p>
                                    If you change your mind, you can change your answer.
                                </p>
                            </div>
                        }
                    </Message>
                </Dimmer>
                }

                {event && event.status === 'cancelled' &&
                    <Dimmer active={true} blurring={true}>
                        <Message error>
                            <h1>This event has been cancelled.</h1>
                        </Message>
                    </Dimmer>
                }

                {event &&
                <div>
                    <h2>
                        {event.first_name}! You've been invited to the following event.
                    </h2>

                    <p>
                        <Moment format='dddd MMMM Do' date={moment.utc(`${event.start_at}`)}/> at {event.start_time}
                    </p>

                    {event.message &&
                        <p>
                            {event.message}
                        </p>
                    }

                    {event.location &&
                        <p>
                            Location: {event.location}
                        </p>
                    }

                    <Button.Group size='large' loading={sending} widths={2}>
                        <Button positive={inOrOut === 'y'} active={inOrOut === 'y'} answer='y'
                                onClick={willAttend}><Icon
                            name='thumbs up outline'/> IN</Button>
                        <Button.Or/>
                        <Button negative={inOrOut === 'n'} active={inOrOut === 'n'} answer='n'
                                onClick={willAttend}><Icon
                            name='thumbs down outline'/> OUT</Button>
                    </Button.Group>

                    {/* Messages */}
                    <Segment loading={loadingMessages}>
                        <div className="ui action input fluid messageCt">
                            <input type="text" value={message} onChange={handleMessage}
                                   placeholder='message this group'/>
                            <Button onClick={sendMessage} loading={sendingMessage}
                                    disabled={!message || message.length < 1}>
                                <Icon name='send'/>
                            </Button>
                        </div>
                        <Comment.Group>
                            {messages && messages.length > 0 ?
                                messages.map(message => (
                                    <Comment>
                                        <Comment.Content>
                                            <Comment.Author
                                                as='a'>{message.first_name} {message.last_name}</Comment.Author>
                                            <Comment.Metadata>
                                                <div>{moment(message.created_on).fromNow()}</div>
                                            </Comment.Metadata>
                                            <Comment.Text>{message.message}</Comment.Text>
                                        </Comment.Content>
                                    </Comment>
                                ))
                                :
                                <div className='no-comments'>
                                    No comments so so far.
                                </div>
                            }
                        </Comment.Group>
                    </Segment>


                </div>

                }

                {players && players.length > 0 &&
                <Segment basic className='players' loading={loadingPlayers}>
                    <h3>
                        Players Invited
                    </h3>

                    {event.min ?
                        <div className='player-count'>
                            Minimum needed: {event.min} | Maximum allowed: {event.max}
                        </div>
                      : ''
                    }

                    <div className='players-list'>
                        {players.map(player => (
                            <div className={`player-ct playing-${player.attending}`}>
                                <div className='name-ct'>
                                    {player.first_name} <br/>
                                    {player.last_name}
                                </div>
                                <div>
                                    {player.attending === 'y' &&
                                    <div style={{textAlign: 'center', padding: 8}}>
                                        <b>IN</b>
                                    </div>
                                    }
                                    {player.attending === 'n' &&
                                    <div style={{textAlign: 'center', padding: 8}}>
                                        <b>OUT</b>
                                    </div>
                                    }
                                    {player.attending === 'invited' &&
                                    <div style={{textAlign: 'center', padding: 8}}>
                                        {player.last_viewed_at &&
                                            <div className='viewed'>
                                                <Popup header={`Viewed ${moment(player.last_viewed_at).fromNow()}`} trigger={<Icon name='eye' />} />
                                            </div>
                                        }
                                        <span>¯\_(ツ)_/¯</span>
                                    </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </Segment>
                }

            </Segment>
        </div>
    )
}